<template>
  <a-layout class='index animated fadeIn'>
    <a-card :bordered='false'>
          <agentList></agentList>
    </a-card>
  </a-layout>
</template>
<script>
import {  onMounted,inject,reactive, toRefs } from "vue";
import agentList from './components/agentList';
export default {
  components: {agentList },
  setup(props) {
    const state = reactive({

    });
    const $router = inject('$router')
    const $Modal = inject('$Modal')
    
    onMounted(() => {
    });
    return {
      ...toRefs(state)
    };
  },
};
</script>